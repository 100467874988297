<template>
    <div class="flex justify-between gap-4">
        <div>{{ name }}</div>
        <div class="flex gap-2">
            <span class="break-all select-none" :class="obscure && obscured ? 'blur-sm' : ''">{{ value }}</span>
            <div class="flex gap-2 justify-end">
                <a v-if="open" :href="value" target="_blank" class="cursor-pointer">
                    <i class="fa-solid fa-arrow-up-right-from-square"></i>
                </a>

                <span v-if="obscure" class="cursor-pointer">
                    <i v-if="obscured" class="fa-regular fa-eye" @click="loadDecryptedPassword"></i>
                    <i v-else class="fa-regular fa-eye-slash" @click="obscured = true"></i>
                </span>
                <copy-icon v-if="copy" :copyText="value" :isPassword="name == 'Passwort'" :credentialId="credentialId" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, toRefs, ref } from "vue";
import axios from "axios";
import CopyIcon from "../components/CopyIcon.vue";

const props = defineProps({
    name: String,
    value: String,
    open: { type: Boolean, default: false },
    obscure: { type: Boolean, default: false },
    copy: { type: Boolean, default: true },
    credentialId: { type: Number, default: -1 },
});

const { name, open, obscure, copy, credentialId } = toRefs(props);
const value = ref(props.value);
const obscured = ref(true);

async function loadDecryptedPassword() {
    if (credentialId.value !== -1 && value.value === "hidden") {
        value.value = (await axios.get("credential/" + credentialId.value + "/decrypted-password")).data.password;
    }
    obscured.value = false;
}
</script>
