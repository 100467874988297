<template>
    <credentials-row v-if="url" name="" :value="url" :open="true" class="text-xs" />
    <div class="w-full bg-white border rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
        <ul
            class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 rounded-t-lg bg-gray-50"
            :id="'tabs-' + projectId"
            :data-tabs-toggle="'#tabs-' + projectId + '-content'"
            role="tablist"
        >
            <li class="mr-2" v-for="(credential, index) in credentials" :key="index">
                <button
                    :id="'tab-' + credential.type + '-' + credential.id + '-' + projectId"
                    type="button"
                    role="tab"
                    :aria-controls="credential.type + '-' + credential.id + '-' + projectId"
                    :aria-selected="index == 0"
                    class="inline-block p-4 pr-8 hover:bg-gray-200 relative"
                    :class="index === 0 ? 'rounded-tl-lg' : ''"
                >
                    {{ credential.name }}
                    <i v-if="credential.private" class="fa-solid fa-user-lock text-green-500 absolute text-[10px] right-4"></i>
                    <i v-else-if="credential.locked" class="fa-solid fa-lock text-red-600 absolute text-[10px] right-4"></i>
                    <i v-else class="fa-solid fa-lock-open text-green-500 absolute text-[10px] right-4"></i>
                </button>
            </li>
            <li class="mr-2">
                <a
                    :href="'project/' + projectId + '/create-credential'"
                    :id="'tab-new-credential-' + projectId"
                    type="button"
                    role="tab"
                    class="inline-block p-4 hover:bg-gray-200"
                >
                    +
                </a>
            </li>
        </ul>
        <div :id="'tabs-' + projectId + '-content'">
            <div
                v-for="(credential, index) in credentials"
                :key="index"
                class="hidden p-4 bg-white rounded-lg md:p-8 dark:bg-gray-800"
                :id="credential.type + '-' + credential.id + '-' + projectId + '-content'"
                role="tabpanel"
                :aria-labelledby="'tab-' + credential.type + '-' + credential.id + '-' + projectId"
            >
                <div v-if="credential.locked" class="text-center flex flex-col space-y-4 items-center">
                    <div class="text-red-600">Diese Zugangsdaten sind für dich nicht verfügbar.</div>
                    <button
                        type="button"
                        class="text-white bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                        @click="requestAccess(credential.id)"
                    >
                        Anfordern
                    </button>
                </div>
                <div v-else>
                    <div class="font-normal text-gray-700 dark:text-gray-400">
                        <credentials-row v-if="credential.url" name="URL" :value="credential.url" :open="true" />
                        <credentials-row v-if="credential.host" name="Host" :value="credential.host" />
                        <credentials-row v-if="credential.username" name="Benutzername" :value="credential.username" />
                        <credentials-row
                            v-if="credential.password"
                            name="Passwort"
                            :value="credential.password"
                            :obscure="true"
                            :credentialId="credential.id"
                        />
                        <div v-if="credential.projectNo || credential.phpVersion || credential.notes">
                            <hr class="m-4" />
                            <credentials-row
                                class="text-sm"
                                v-if="credential.projectNo"
                                :name="credential.label == 'Strato' ? 'Auftragsnummer' : 'Projektnummer'"
                                :value="credential.projectNo"
                                :copy="false"
                            />
                            <credentials-row class="text-sm" v-if="credential.phpVersion" name="PHP-Version" :value="credential.phpVersion" :copy="false" />

                            <div v-if="credential.notes" class="mt-4 text-sm">{{ credential.notes }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, toRefs, onMounted } from "vue";
import { Tabs } from "flowbite";
import axios from "axios";
import CredentialsRow from "../components/CredentialsRow.vue";

onMounted(() => {
    let tabElements = [];

    credentials.value.forEach((credential) => {
        tabElements.push({
            id: "tab-" + credential.type + "-" + credential.id + "-" + projectId.value,
            triggerEl: document.querySelector("#tab-" + credential.type + "-" + credential.id + "-" + projectId.value),
            targetEl: document.querySelector("#" + credential.type + "-" + credential.id + "-" + projectId.value + "-content"),
        });
    });

    // options with default values
    const options = {
        defaultTabId: "settings",
        activeClasses: "bg-slate-700 text-white hover:bg-slate-600",
        inactiveClasses: "text-slate-600",
    };

    new Tabs(tabElements, options);
});

const props = defineProps({
    url: String,
    credentials: Array,
    projectId: Number,
});

const emits = defineEmits(['access-requested'])

const { url, credentials, projectId } = toRefs(props);

const requestAccess = (credential_id) => {
    axios.post("credential/" + credential_id + "/request-access").then(() => {
        emits("access-requested");
    });
};
</script>
