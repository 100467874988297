import { createRouter, createWebHistory } from "vue-router";
import RegisterView from "../views/RegisterView.vue";
import LoginView from "../views/LoginView.vue";
import LogoutView from "../views/LogoutView.vue";
import UsersView from "../views/UsersView.vue";
import CustomersView from "../views/CustomersView.vue";
import ProfileSection from "../views/user/ProfileSection.vue";
import EditProfile from "../views/user/EditProfile.vue";
import CreateCustomer from "../views/customer/CreateCustomer.vue";
import CreateProject from "../views/customer/CreateProject.vue";
import CreateCredential from "../views/customer/CreateCredential.vue";
import EditCustomer from "../views/customer/EditCustomer.vue";
import EditProject from "../views/customer/EditProject.vue";
import EditCredential from "../views/customer/EditCredential.vue";

const user = JSON.parse(window.localStorage.getItem("user"));

const requiresAuth = (to, from, next) => {
    if (!user || !user.token) {
        next({
            name: "login",
        });
    } else {
        next();
    }
};
const requiresAdmin = (to, from, next) => {
    if (!user || !user.token || !user.roles.find((role) => role.id === 1)) {
        next(from);
    } else {
        next();
    }
};

const routes = [
    {
        path: "/login",
        name: "login",
        component: LoginView,
        beforeEnter(to, from, next) {
            if (user && user.token) {
                next("/customers");
            } else {
                next();
            }
        },
    },
    {
        path: "/",
        name: "home",
        redirect: "customers",
        beforeEnter: requiresAuth,
    },
    {
        path: "/register",
        name: "register",
        component: RegisterView,
        beforeEnter: requiresAdmin,
    },
    {
        path: "/users",
        name: "users",
        component: UsersView,
        beforeEnter: requiresAuth,
    },
    {
        path: "/user/:id",
        name: "user",
        component: ProfileSection,
        beforeEnter: requiresAuth,
    },
    {
        path: "/user/:id/edit",
        name: "EditProfile",
        component: EditProfile,
        beforeEnter: requiresAuth,
    },
    {
        path: "/customers",
        name: "customers",
        component: CustomersView,
        beforeEnter: requiresAuth,
    },
    {
        path: "/customer/create",
        name: "CreateCustomer",
        component: CreateCustomer,
        beforeEnter: requiresAuth,
    },
    {
        path: "/customer/:id/create-project",
        name: "CreateProject",
        component: CreateProject,
        beforeEnter: requiresAuth,
    },
    {
        path: "/customer/:id/edit",
        name: "EditCustomer",
        component: EditCustomer,
        beforeEnter: requiresAuth,
    },
    {
        path: "/project/:id/create-credential",
        name: "CreateCredential",
        component: CreateCredential,
        beforeEnter: requiresAuth,
    },
    {
        path: "/project/:id/edit",
        name: "EditProject",
        component: EditProject,
        beforeEnter: requiresAuth,
    },
    {
        path: "/credential/:id/edit",
        name: "EditPCredential",
        component: EditCredential,
        beforeEnter: requiresAuth,
    },
    {
        path: "/logout",
        name: "Logout",
        component: LogoutView,
        beforeEnter: requiresAuth,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
