<template>
    <div class="pl-8" data-accordion="open">
        <div
            class="grid grid-cols-2 text-sm border-b border-gray-200 gap-x-16 cursor-pointer"
            :class="headline ? 'p-4 font-medium text-gray-900 bg-gray-100 border-t' : 'px-4 text-gray-700'"
        >
            <div :id="id" :data-accordion-target="'#' + id + 'body'">
                {{ name }}
            </div>

            <div @click.prevent="emit('toggleGranted')">

                <i v-if="granted == 'yes'"
                    class="fa-solid fa-check-double"
                    :class="parentGranted ? 'text-green-500' : 'text-gray-500'"
                    aria-hidden="true"
                    fill="currentColor"></i>
                <svg
                    v-else-if="granted == 'partial'"
                    class="w-5 h-5 text-gray-500"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                    ></path>
                </svg>
                <svg
                    v-else
                    class="w-5 h-5"
                    :class="parentGranted ? 'text-red-500' : 'text-gray-500'"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                    ></path>
                </svg>
            </div>
        </div>
        <div v-if="$slots" :aria-labelledby="id" class="hidden" :id="id + 'body'">
            <slot />
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, toRefs, onMounted } from "vue";
import { initAccordions } from "flowbite";

onMounted(() => {
    initAccordions();
});

const props = defineProps({
    headline: { type: Boolean, default: false },
    name: String,
    granted: { type: String, default: "no" },
    parentGranted: Boolean,
});

const emit = defineEmits(["toggleGranted"]);

const { headline, name, granted, parentGranted } = toRefs(props);
const id = "id" + Math.ceil(Math.random() * 1000000);
</script>
