<template>
    <div id="register">
        <div class="w-full p-6 flex justify-center items-center">
            <div class="w-full max-w-xs">
                <div class="bg-slate-800 p-8 shadow rounded mb-6">
                    <div class="mb-4">
                        <text-input
                            label="E-Mail"
                            :labelColor="false"
                            placeholder="login@email.com"
                            v-model:input="email"
                            inputType="email"
                            :error="errors.email ? errors.email[0] : ''"
                        />
                    </div>

                    <div class="mb-4">
                        <text-input
                            label="Passwort"
                            :labelColor="false"
                            placeholder="Passwort"
                            v-model:input="password"
                            inputType="password"
                            :error="errors.password ? errors.password[0] : ''"
                            @keyup.enter="login"
                        />
                    </div>

                    <button class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide" type="submit" @click="login">Einloggen</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import { useUserStore } from "../store/user-store";
import TextInput from "../components/global/TextInput.vue";

const userStore = useUserStore();

let errors = ref([]);
let email = ref(null);
let password = ref(null);

const login = async () => {
    errors.value = [];
    try {
        let result = await axios.post("login", {
            email: email.value,
            password: password.value,
        });

        userStore.setUserDetails(result);
        window.location.reload();
    } catch (error) {
        errors.value = error.response.data.errors;
    }
};
</script>
