<template>
    <div id="register">
        <div class="w-full p-6 flex justify-center items-center">
            <div class="w-full max-w-xs">
                <div class="bg-slate-800 p-8 shadow rounded mb-6">
                    <div class="mb-4">
                        <text-input
                            label="Vorname"
                            :labelColor="false"
                            placeholder="Vorname"
                            v-model:input="firstName"
                            inputType="text"
                            :error="errors.first_name ? errors.first_name[0] : ''"
                        />
                    </div>

                    <div class="mb-4">
                        <text-input
                            label="Nachname"
                            :labelColor="false"
                            placeholder="Nachname"
                            v-model:input="lastName"
                            inputType="text"
                            :error="errors.last_name ? errors.last_name[0] : ''"
                        />
                    </div>

                    <div class="mb-4">
                        <text-input
                            label="E-Mail"
                            :labelColor="false"
                            placeholder="login@email.com"
                            v-model:input="email"
                            inputType="email"
                            :error="errors.email ? errors.email[0] : ''"
                        />
                    </div>

                    <div class="mb-4">
                        <text-input
                            label="Passwort"
                            :labelColor="false"
                            placeholder="Passwort"
                            v-model:input="password"
                            inputType="password"
                            :error="errors.password ? errors.password[0] : ''"
                        />
                    </div>

                    <div class="mb-4">
                        <text-input
                            label="Passwort wiederholen"
                            :labelColor="false"
                            placeholder="Passwort wiederholen"
                            v-model:input="confirmPassword"
                            inputType="password"
                        />
                    </div>

                    <div class="mb-4">
                        <select-input label="Rollen" :labelColor="false" v-model:input="roles" :error="errors.roles ? errors.roles[0] : ''">
                            <option v-for="role in all_roles" :value="[role.id.toString()]" :key="role.id">{{ role.name }}</option>
                        </select-input>
                    </div>

                    <div class="mb-4">
                        <select-input
                            label="Agenturen"
                            :labelColor="false"
                            :multiple="true"
                            v-model:input="agencies"
                            :error="errors.agencies ? errors.agencies[0] : ''"
                        >
                            <option v-for="agency in all_agencies" :value="agency.id.toString()" :key="agency.id">{{ agency.name }}</option>
                        </select-input>
                    </div>

                    <button class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide" type="submit" @click="register">
                        Benutzer anlegen
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import TextInput from "../components/global/TextInput.vue";
import SelectInput from "../components/global/SelectInput.vue";

const errors = ref([]);
const firstName = ref(null);
const lastName = ref(null);
const email = ref(null);
const password = ref(null);
const confirmPassword = ref(null);
const roles = ref([]);
const agencies = ref([]);

const all_roles = ref([]);
const all_agencies = ref([]);

const router = useRouter();

onMounted(async () => {
    all_roles.value = (await axios.get("roles")).data;
    all_agencies.value = (await axios.get("agencies")).data;
});

const register = async () => {
    errors.value = [];
    try {
        await axios.post("register", {
            first_name: firstName.value,
            last_name: lastName.value,
            email: email.value,
            password: password.value,
            password_confirmation: confirmPassword.value,
            roles: roles.value,
            agencies: agencies.value,
        });

        router.push("users");
    } catch (error) {
        errors.value = error.response.data.errors;
    }
};
</script>
