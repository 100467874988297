import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "./axios.js";

import router from "./router";

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import FlashMessage from '@smartweb/vue-flash-message';

import "./index.css";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);
app.use(router);
app.use(pinia);
app.use(FlashMessage);
app.mount("#app");
