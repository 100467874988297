<template>
    <div class="flex justify-between">
        <h2 class="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">Benutzer</h2>
        <router-link to="register" class="mb-2 mr-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white cursor-pointer">+</router-link>
    </div>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-10 mx-auto">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs bg-slate-700 text-white">
                <tr>
                    <th scope="col" class="px-6 py-3">Benutzer</th>
                    <th scope="col" class="px-6 py-3">Rollen</th>
                    <th scope="col" class="px-6 py-3">Agentur</th>
                    <th scope="col" class="px-6 py-3">Aktionen</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="user in users"
                    :key="user.id"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                    <td class="px-6 py-4">
                        {{ user.first_name }} {{ user.last_name }}<br>
                        <small class="whitespace-nowrap">{{ user.email }}</small>
                    </td>

                    <td class="px-6 py-4 text-xs">
                        <div class="flex flex-wrap">
                        <span
                            v-for="(role, index) in user.roles"
                            :key="index"
                            :class="'bg-' + role.name + ' text-' + role.name + ' border-2 border-' + role.name"
                            class="text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full mb-1"
                        >
                            {{ role.name }}
                        </span></div>
                    </td>
                    <td class="px-6 py-4 text-xs">
                        <div class="flex flex-wrap">
                        <span
                            v-for="(agency, index) in user.agencies"
                            :key="index"
                            :class="
                                'bg-' +
                                agency.name.toLowerCase().replace('ö', 'oe') +
                                ' text-' +
                                agency.name.toLowerCase().replace('ö', 'oe') +
                                ' border-2 border-' +
                                agency.name.toLowerCase().replace('ö', 'oe')
                            "
                            class="text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full mb-1"
                        >
                            {{ agency.name }}
                        </span></div>
                    </td>
                    <td class="px-6 py-4">
                        <div class="flex space-x-4">
                            <a href="#" type="button" :data-modal-target="'user-' + user.id + '-show-box'" :data-modal-show="'user-' + user.id + '-show-box'">
                                <i class="fa-regular fa-eye"></i>
                            </a>

                            <a
                                href="#"
                                type="button"
                                :data-modal-target="'user-' + user.id + '-edit-box'"
                                :data-modal-show="'user-' + user.id + '-edit-box'"
                                v-if="!false"
                            >
                                <i class="fa-solid fa-pen-to-square"></i>
                            </a>

                            <a href="#" type="button" v-if="!false">
                                <i class="fa-solid fa-trash text-red-500"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div
            v-for="user in users"
            :key="user.id"
            :id="'user-' + user.id + '-show-box'"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
        >
            <div class="relative w-full h-full max-w-2xl md:h-auto">
                <!-- Modal content -->
                <form action="#" class="relative bg-white rounded-lg shadow dark:bg-gray-700 max-h-full overflow-y-auto">
                    <!-- Modal header -->
                    <div class="flex justify-between p-4 border-b rounded-t dark:border-gray-600 items-center gap-4">
                        <h3 class="text-2xl font-semibold text-gray-900 dark:text-white">{{ user.name }}</h3>
                        <div>
                            <span
                                v-for="(role, index) in user.roles"
                                :key="index"
                                :class="'bg-' + role.color + '-100 text-' + role.color + '-800'"
                                class="text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                            >
                                {{ role.name }}
                            </span>
                        </div>
                        <button
                            type="button"
                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            :data-modal-hide="'user-' + user.id + '-show-box'"
                        >
                            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <!-- Modal body -->
                    <permissions-grid :data="data" @toggleGranted="toggleGranted" />
                    <!-- Modal footer -->
                </form>
            </div>
        </div>
        <div
            v-for="user in users"
            :key="user.id"
            :id="'user-' + user.id + '-edit-box'"
            tabindex="-1"
            aria-hidden="true"
            class="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
        >
            <div class="relative w-full h-full max-w-2xl md:h-auto">
                <!-- Modal content -->
                <form action="#" class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <!-- Modal header -->
                    <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                        <h3 class="text-2xl font-semibold text-gray-900 dark:text-white">{{ user.name }}</h3>
                        <button
                            type="button"
                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            :data-modal-hide="'user-' + user.id + '-edit-box'"
                        >
                            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <!-- Modal body -->
                    <div class="p-6 space-y-6">
                        <user-form />
                    </div>
                    <!-- Modal footer -->
                    <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                        <button
                            type="submit"
                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                        >
                            Benutzer anlegen / updaten
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { initModals } from "flowbite";
import axios from "axios";

import UserForm from "../components/partials/crud/UserForm.vue";
import PermissionsGrid from "../components/PermissionsGrid.vue";

const users = ref([]);

onMounted(() => {
    axios.get("users").then((c) => {
        users.value = c.data.paginate.data;

        // nextTick(() => {
        //     customers.value.forEach((customer) => {
        //         const $buttonElement = document.querySelector('[data-modal-show="customer-' + customer.id + '-credentials-box"]');
        //         const $modalElement = document.querySelector("#customer-" + customer.id + "-credentials-box");
        //         const $closeButton = document.querySelector('[data-modal-hide="customer-' + customer.id + '-credentials-box"]');

        //         const modalOptions = {
        //             backdropClasses: "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
        //         };

        //         if ($modalElement) {
        //             const modal = new Modal($modalElement, modalOptions);
        //             $buttonElement.addEventListener("click", () => modal.show());
        //             $closeButton.addEventListener("click", () => modal.hide());
        //         }
        //     });
        // });
    });

    initModals();
});

function switchGranted(item) {
    item.granted = item.granted === "yes" ? "no" : "yes";
}

const debugCounter = ref(0);
function toggleGranted(item) {
    debugCounter.value = 0;
    toggleGrantedSub(item);
}

function toggleGrantedSub(item) {
    if (debugCounter.value++ >= 10) return;
    if (item.children) {
        let allChildrenGranted = true;
        let allChildrenNotGranted = true;
        for (let i = 0; i < item.children.length; i++) {
            toggleGrantedSub(item.children[i]);
            if (item.children[i].granted !== "yes") {
                allChildrenGranted = false;
            }
            if (item.children[i].granted !== "no") {
                allChildrenNotGranted = false;
            }
        }
        if (allChildrenGranted) {
            item.granted = "yes";
        } else if (allChildrenNotGranted) {
            item.granted = "no";
        } else {
            item.granted = "partial";
        }
    } else {
        switchGranted(item);
    }
    if (item.parent && item.children) toggleGrantedSub(item.parent);
}

// const users = [
//     {
//         id: 0,
//         name: "Judith Budde-Renfordt",
//         roles: [
//             {
//                 color: "blue",
//                 name: "admin",
//             },
//             {
//                 color: "green",
//                 name: "bmd",
//             },
//             {
//                 color: "pink",
//                 name: "hl",
//             },
//         ],
//     },
//     {
//         id: 1,
//         name: "Frank Höhne",
//         roles: [
//             {
//                 color: "blue",
//                 name: "admin",
//             },
//             {
//                 color: "pink",
//                 name: "hl",
//             },
//         ],
//     },
//     {
//         id: 2,
//         name: "Matthias Dax",
//         roles: [
//             {
//                 color: "blue",
//                 name: "admin",
//             },
//             {
//                 color: "green",
//                 name: "bmd",
//             },
//         ],
//     },
//     {
//         id: 3,
//         name: "Torge",
//         roles: [
//             {
//                 color: "yellow",
//                 name: "restricted",
//             },
//             {
//                 color: "green",
//                 name: "bmd",
//             },
//             {
//                 color: "pink",
//                 name: "hl",
//             },
//         ],
//     },
// ];

// const data = [
//     {
//         id: 0,
//         name: "bmd",
//         granted: "partial",
//         children: [
//             {
//                 id: 0,
//                 name: "Budde Mediendesign",
//                 granted: "partial",
//                 children: [
//                     {
//                         id: 0,
//                         name: "budde-dev.de",
//                         granted: "yes",
//                         type: "productive",
//                         url: "https://www.budde-dev.de",
//                         children: [
//                             {
//                                 id: 0,
//                                 key: "webhost",
//                                 label: "Mittwald",
//                                 granted: "yes",
//                                 url: "https://login.mittwald.de/",
//                                 username: "r31533",
//                                 password: "5nE8P39me+:WobkCxPcf",
//                                 projectNo: "p633606",
//                                 phpVersion: "8.1",
//                             },
//                         ],
//                     },
//                     {
//                         id: 1,
//                         name: "BMD.de",
//                         granted: "no",
//                         type: "productive",
//                         url: "https://www.budde-mediendesign.de",
//                         children: [
//                             {
//                                 id: 0,
//                                 key: "webhost",
//                                 label: "Strato",
//                                 granted: "no",
//                                 url: "https://www.strato.de/apps/CustomerService#/skl",
//                                 username: "73270465",
//                                 password: "tq8Cy3!z",
//                                 projectNo: "4395854",
//                                 phpVersion: "8.1",
//                             },
//                             {
//                                 id: 1,
//                                 key: "sftp",
//                                 label: "SFTP",
//                                 granted: "no",
//                                 host: "xxx",
//                                 username: "xxx",
//                                 password: "xxx",
//                             },
//                         ],
//                     },
//                     {
//                         id: 2,
//                         name: "BMD.com",
//                         granted: "partial",
//                         type: "productive",
//                         url: "https://www.budde-mediendesign.com",
//                         children: [
//                             {
//                                 id: 0,
//                                 key: "webhost",
//                                 label: "Strato",
//                                 granted: "yes",
//                                 url: "https://www.strato.de/apps/CustomerService#/skl",
//                                 username: "73270465",
//                                 password: "tq8Cy3!z",
//                                 projectNo: "5964251",
//                                 phpVersion: "?",
//                             },
//                             {
//                                 id: 1,
//                                 key: "plesk",
//                                 label: "Plesk",
//                                 granted: "no",
//                                 url: "http://www.budde-mediendesign.com:8443",
//                                 username: "root",
//                                 password: "ySB8g686",
//                             },
//                             {
//                                 id: 2,
//                                 key: "sftp",
//                                 label: "SFTP",
//                                 granted: "yes",
//                                 host: "xxx",
//                                 username: "xxx",
//                                 password: "xxx",
//                             },
//                             {
//                                 id: 3,
//                                 key: "ssh",
//                                 label: "SSH",
//                                 granted: "no",
//                                 host: "xxx",
//                                 username: "xxx",
//                                 password: "xxx",
//                             },
//                         ],
//                     },
//                 ],
//             },
//             {
//                 id: 3,
//                 name: "Headline",
//                 granted: "no",
//                 children: [
//                     {
//                         id: 0,
//                         name: "Website Dev",
//                         granted: "no",
//                         type: "dev",
//                         url: "https://p624699.mittwaldserver.info",
//                         children: [
//                             {
//                                 id: 0,
//                                 key: "webhost",
//                                 label: "Mittwald",
//                                 granted: "no",
//                                 url: "https://login.mittwald.de/",
//                                 username: "r31533",
//                                 password: "5nE8P39me+:WobkCxPcf",
//                                 projectNo: "p624699",
//                                 phpVersion: "?",
//                             },
//                             {
//                                 id: 1,
//                                 key: "sftp",
//                                 label: "SFTP",
//                                 granted: "no",
//                                 host: "185.166.21.127",
//                                 username: "p624699",
//                                 password: "xxx",
//                             },
//                             {
//                                 id: 2,
//                                 key: "sftp-2",
//                                 label: "SFTP",
//                                 granted: "no",
//                                 host: "185.166.21.127",
//                                 username: "p624699f1",
//                                 password: "xxx",
//                             },
//                         ],
//                     },
//                     {
//                         id: 1,
//                         name: "Website Live",
//                         granted: "no",
//                         type: "productive",
//                         url: "https://www.headonline.de",
//                         children: [
//                             {
//                                 id: 0,
//                                 locked: true,
//                                 granted: "no",
//                                 key: "webhost",
//                                 label: "Mittwald",
//                             },
//                             {
//                                 id: 1,
//                                 locked: true,
//                                 granted: "no",
//                                 key: "sftp",
//                                 label: "SFTP",
//                             },
//                         ],
//                     },
//                 ],
//             },
//             {
//                 id: 1,
//                 name: "ACE Zydek",
//                 granted: "no",
//                 children: [
//                     {
//                         id: 0,
//                         name: "Website Live",
//                         granted: "no",
//                         type: "productive",
//                         url: "https://www.ace-zydek.de",
//                         children: [
//                             {
//                                 id: 0,
//                                 key: "sftp",
//                                 label: "SFTP",
//                                 granted: "no",
//                                 host: "xxx",
//                                 username: "xxx",
//                                 password: "xxx",
//                             },
//                         ],
//                     },
//                 ],
//             },
//             {
//                 id: 2,
//                 name: "Fass-Schmiede",
//                 granted: "no",
//                 children: [
//                     {
//                         id: 0,
//                         name: "Prestashop Dev",
//                         granted: "no",
//                         type: "dev",
//                         url: "https://fs.budde-mediendesign.com",
//                         children: [
//                             {
//                                 id: 0,
//                                 key: "http-auth",
//                                 label: ".htaccess",
//                                 granted: "no",
//                                 url: "https://fs.budde-mediendesign.com",
//                                 username: "admin",
//                                 password: "fs",
//                             },
//                             {
//                                 id: 1,
//                                 key: "db",
//                                 label: "Datenbank",
//                                 granted: "no",
//                                 url: "https://fs.budde-mediendesign.com/phpMyAdmin",
//                                 host: "xxx",
//                                 username: "xxx",
//                                 password: "xxx",
//                             },
//                             {
//                                 id: 2,
//                                 key: "sftp",
//                                 label: "SFTP",
//                                 granted: "no",
//                                 host: "xxx",
//                                 username: "xxx",
//                                 password: "xxx",
//                             },
//                         ],
//                     },
//                     {
//                         id: 3,
//                         name: "Prestashop Live",
//                         granted: "no",
//                         type: "productive",
//                         url: "https://www.fassschmiede.de",
//                         children: [
//                             {
//                                 id: 0,
//                                 key: "sftp",
//                                 label: "SFTP",
//                                 granted: "no",
//                                 host: "home500020251.1and1-data.host",
//                                 username: "u75159547-bmd",
//                                 password: "Gx:ym%-XG(-C+%SqBbp4c$thHwgKrP{K",
//                             },
//                             {
//                                 id: 1,
//                                 key: "prestashop",
//                                 label: "Prestashop",
//                                 granted: "no",
//                                 url: "https://www.fassschmiede.de/Backoffice",
//                                 username: "info@budde-mediendesign.de",
//                                 password: "agoihero",
//                                 notes: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique excepturi vitae possimus ullam repudiandae, quis consequatur modi, facilis corrupti animi enim assumenda laborum. Sint doloribus, blanditiis iste odit repellendus dolores.",
//                             },
//                             {
//                                 id: 2,
//                                 locked: true,
//                                 key: "webhost",
//                                 label: "Ionos",
//                                 granted: "no",
//                                 url: "https://login.ionos.de",
//                                 username: "fassschmiede.de",
//                                 password: "k-jj}$NrHx:<_H,j2xevU,rFwLB@f@%e",
//                             },
//                             {
//                                 id: 3,
//                                 key: "sofort",
//                                 label: "Sofort.",
//                                 granted: "no",
//                                 url: "https://www.sofort.com",
//                                 username: "0123456",
//                                 password: "0123456",
//                             },
//                             {
//                                 id: 4,
//                                 key: "billbee",
//                                 label: "Billbee",
//                                 granted: "no",
//                                 url: "???",
//                                 username: "0123456",
//                                 password: "0123456",
//                             },
//                         ],
//                     },
//                 ],
//             },
//             {
//                 id: 10,
//                 name: "Geldsetzer & Schäfers",
//                 granted: "no",
//                 children: [
//                     {
//                         id: 0,
//                         name: "Website Dev",
//                         granted: "no",
//                         type: "dev",
//                         url: "https://p619676.mittwaldserver.info",
//                         children: [
//                             {
//                                 id: 0,
//                                 key: "wp",
//                                 label: "Wordpress",
//                                 granted: "no",
//                                 url: "https://p619676.mittwaldserver.info/wp-admin",
//                                 username: "Budde Mediendesign",
//                                 password: "test123",
//                             },
//                         ],
//                     },
//                     {
//                         id: 1,
//                         name: "Website Live",
//                         granted: "no",
//                         type: "productive",
//                         url: "https://www.geldsetzer.de",
//                         children: [
//                             {
//                                 id: 0,
//                                 key: "wp",
//                                 label: "Wordpress",
//                                 granted: "no",
//                                 url: "https://www.geldsetzer.de/wp-admin",
//                                 username: "Budde Mediendesign",
//                                 password: "7Hj2Khnog@F6PTVl%fVj#L#d",
//                             },
//                             {
//                                 id: 1,
//                                 key: "db",
//                                 label: "Datenbank",
//                                 granted: "no",
//                                 url: "https://www.geldsetzer.de/phpMyAdm/",
//                                 host: "localhost",
//                                 username: "c23gunds_dbusr1",
//                                 password: "@YXre8ieZQFuu",
//                                 notes: "database: c23gunds_db1",
//                             },
//                         ],
//                     },
//                 ],
//             },
//         ],
//     },
//     {
//         id: 1,
//         name: "hl",
//         children: [],
//     },
// ];

// function setParent(data, element, parent) {
//     element.parent = parent;
//     if (element.children) {
//         for (let i = 0; i < element.children.length; i++) {
//             setParent(data, element.children[i], element);
//         }
//     }
// }
// setParent(data, data[0], null);
</script>
