import axios from "axios";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
    state: () => ({
        id: null,
        token: null,
        firstName: null,
        lastName: null,
        email: null,
        image: null,
        agencies: null,
        roles: null,
    }),

    actions: {
        async setUserDetails(res) {
            this.$state.id = res.data.user.id;
            this.$state.token = res.data.token;
            this.$state.firstName = res.data.user.first_name;
            this.$state.lastName = res.data.user.last_name;
            this.$state.email = res.data.user.email;
            this.$state.image = res.data.user.image;
            this.$state.agencies = res.data.user.agencies;
            this.$state.roles = res.data.user.roles;
        },

        async fetchUser() {
            let res = await axios.get("users/" + this.$state.id);

            this.$state.id = res.data.user.id;
            this.$state.firstName = res.data.user.first_name;
            this.$state.lastName = res.data.user.last_name;
            this.$state.email = res.data.user.email;

            if (res.data.user.image) {
                this.$state.image = process.env.VUE_APP_BACKEND + "images/users/" + res.data.user.image;
            } else {
                this.$state.image = process.env.VUE_APP_BACKEND + "images/users/default.webp";
            }

            this.$state.agencies = res.data.user.agencies;
            this.$state.roles = res.data.user.roles;
        },

        clearUser() {
            this.$state.id = null;
            this.$state.token = null;
            this.$state.firstName = null;
            this.$state.lastName = null;
            this.$state.email = null;
            this.$state.image = null;
            this.$state.agencies = null;
            this.$state.roles = null;
        },
    },
    persist: true,
});
