<template>
    <div v-if="errors.length" class="text-red-600">{{ errors }}</div>
    <div v-else>Wird abgemeldet...</div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useUserStore } from "../store/user-store";

const userStore = useUserStore();
let errors = ref([]);

onMounted(() => {
    logout();
});

const logout = () => {
    try {
        axios.post("logout");

        userStore.clearUser();
        window.location.reload();
    } catch (error) {
        errors.value = error.response.data.error;
    }
};
</script>
