<template>
    <span @click="copy(copyText)" class="cursor-pointer transition-colors">
        <i :class="copied ? 'fa-solid fa-check text-green-500' : 'fa-regular fa-copy'"></i>
    </span>
</template>

<script setup>
import { defineProps, toRefs, ref } from "vue";
import axios from "axios";

const props = defineProps({
    copyText: String,
    isPassword: { type: Boolean, default: false },
    credentialId: { type: Number, default: -1 },
});

const { copyText, isPassword, credentialId } = toRefs(props);
let copied = ref(false);

async function copy(text) {
    if (isPassword.value && text === "hidden") {
        text = (await axios.get("credential/" + credentialId.value + "/decrypted-password")).data.password;
    }

    navigator.clipboard.writeText(text);
    copied.value = true;
    window.setTimeout(() => {
        copied.value = false;
    }, 1000);
}
</script>
