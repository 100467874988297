<template>
    <div id="edit-profile" class="container max-w-4xl mx-auto py-20 px-6">
        <div class="text-gray-900 text-xl">
            <router-link :to="'/customer/' + project.customer.id + '/edit'" v-if="project.customer">{{ project.customer.name }}:</router-link>
            Projekt bearbeiten
        </div>

        <div class="bg-slate-700 w-full h-1"></div>

        <div class="flex flex-wrap mt-4 mb-6">
            <div class="w-full sm:w-1/2 px-3">
                <text-input
                    label="Name"
                    input-type="text"
                    v-model:input="project.name"
                    :error="errors.name ? errors.name[0] : ''"
                    @keyup.enter="updateProject"
                ></text-input>
            </div>
            <div class="w-full sm:w-1/2 px-3">
                <select-input label="Typ" v-model:input="project.type">
                    <option value="dev">Dev</option>
                    <option value="live">Live</option>
                    <option value="other">Sonstiges</option>
                </select-input>
            </div>
            <div class="w-full sm:w-1/2 px-3">
                <text-input
                    label="URL"
                    input-type="url"
                    v-model:input="project.url"
                    :error="errors.url ? errors.url[0] : ''"
                    @keyup.enter="updateProject"
                ></text-input>
            </div>
            <div class="w-full px-3">
                <submit-form-button @click="updateProject">Änderungen speichern</submit-form-button>
            </div>
        </div>

        <div class="text-gray-900 text-xl">Zugangsdaten</div>
        <div class="bg-slate-700 w-full h-1"></div>

        <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-4 mx-auto">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs bg-slate-700 text-white">
                    <tr>
                        <th scope="col" class="px-6 py-3">Name</th>
                        <th scope="col" class="px-6 py-3">Typ</th>
                        <th scope="col" class="px-6 py-3">Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="credential in project.credentials"
                        :key="credential.id"
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                        <td class="px-6 py-4">
                            <span class="relative pr-4">
                                {{ credential.name }}
                                <i v-if="credential.private" class="fa-solid fa-user-lock text-green-500 absolute text-[10px] right-0"></i>
                                <i v-else-if="credential.locked" class="fa-solid fa-lock text-red-600 absolute text-[10px] right-0"></i>
                                <i v-else class="fa-solid fa-lock-open text-green-500 absolute text-[10px] right-0"></i>
                            </span>
                        </td>
                        <td class="px-6 py-4">{{ credential.type }}</td>
                        <td class="px-6 py-4">
                            <div class="flex space-x-4" v-if="!credential.locked">
                                <router-link :to="'/credential/' + credential.id + '/edit'" type="button" v-if="!false">
                                    <i class="fa-solid fa-pen-to-square"></i>
                                </router-link>

                                <a href="#" type="button" v-if="!false">
                                    <i class="fa-solid fa-trash text-red-500"></i>
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRoute} from "vue-router";
import { flashMessage } from "@smartweb/vue-flash-message";
import TextInput from "@/components/global/TextInput.vue";
import SelectInput from "@/components/global/SelectInput.vue";
import SubmitFormButton from "@/components/global/SubmitFormButton.vue";
import axios from "axios";

const route = useRoute();

const project = ref({ name: "", type: "", url: "" });
const errors = ref([]);

onMounted(async () => {
    project.value = (await axios.get("project/" + route.params.id)).data.project;
});

onMounted(() => {
    document.getElementsByTagName("input")[0].focus();
});

const updateProject = async () => {
    errors.value = [];
    let data = new FormData();
    data.append("name", project.value.name || "");
    data.append("type", project.value.type || "");
    data.append("url", project.value.url || "");
    data.append("customer_id", project.value.customer_id || "");
    try {
        const result = await axios.post("project/" + project.value.id + "?_method=PUT", data);

        if (result.status == 200) {
            flashMessage.show({
                type: "success",
                title: "Speichern erfolgreich",
                text: result.data.message,
                image: "/img/flash-message-icons/success.svg",
            });
        }
    } catch (err) {
        errors.value = err.response.data.errors;

        let i = 0;
        Object.values(errors.value).forEach((error) => {
            window.setTimeout(() => {
                flashMessage.show({
                    type: "error",
                    title: "Fehler",
                    text: error,
                    image: "/img/flash-message-icons/error.svg",
                });
            }, i++ * 250);
        });
    }
};
</script>
