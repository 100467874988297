<template>
    <div class="p-6 space-y-6">
        <div class="w-full overflow-x-auto">
            <div class="overflow-hidden min-w-max">
                <permissions-grid-row
                    v-for="agency in agencies"
                    :key="agency.id"
                    :name="agency.name"
                    :granted="agency.granted"
                    :parentGranted="true"
                    :headline="true"
                    @toggleGranted="emit('toggleGranted', agency)"
                >
                    <permissions-grid-row
                        v-for="customer in agency.children"
                        :key="customer.id"
                        :name="customer.name"
                        :granted="customer.granted"
                        :parentGranted="agency.granted !== 'no'"
                        @toggleGranted="emit('toggleGranted', customer)"
                    >
                        <permissions-grid-row
                            v-for="project in customer.children"
                            :key="project.id"
                            :name="project.name"
                            :granted="project.granted"
                            :parentGranted="agency.granted !== 'no' && customer.granted !== 'no'"
                            @toggleGranted="emit('toggleGranted', project)"
                        >
                            <permissions-grid-row
                                v-for="credential in project.children"
                                :key="credential.key"
                                :name="credential.label"
                                :granted="credential.granted"
                                :parentGranted="agency.granted !== 'no' && customer.granted !== 'no' && project.granted !== 'no'"
                                @toggleGranted="emit('toggleGranted', credential)"
                            />
                        </permissions-grid-row>
                    </permissions-grid-row>
                </permissions-grid-row>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, toRefs } from "vue";
import PermissionsGridRow from "../components/PermissionsGridRow.vue";

const props = defineProps({
    agencies: Array,
});

const emit = defineEmits(["toggleGranted"]);

const { agencies } = toRefs(props);
</script>
