<template>
    <div id="edit-profile" class="container max-w-4xl mx-auto py-20 px-6">
        <div class="text-gray-900 text-xl">
            <template v-if="customer">{{ customer.name }}:</template>
            Neues Projekt anlegen
        </div>

        <div class="bg-slate-700 w-full h-1"></div>

        <div class="flex flex-wrap mt-4 mb-6">
            <div class="w-full sm:w-1/2 px-3">
                <text-input
                    label="Name"
                    input-type="text"
                    v-model:input="name"
                    :error="errors.name ? errors.name[0] : ''"
                    @keyup.enter="createProject"
                ></text-input>
            </div>
            <div class="w-full sm:w-1/2 px-3">
                <select-input label="Typ" v-model:input="type">
                    <option value="dev">Dev</option>
                    <option value="live">Live</option>
                    <option value="other">Sonstiges</option>
                </select-input>
            </div>
            <div class="w-full sm:w-1/2 px-3">
                <text-input label="URL" input-type="url" v-model:input="url" :error="errors.url ? errors.url[0] : ''" @keyup.enter="createProject"></text-input>
            </div>
            <div class="w-full px-3">
                <submit-form-button @click="createProject">Projekt anlegen</submit-form-button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { flashMessage } from "@smartweb/vue-flash-message";
import TextInput from "@/components/global/TextInput.vue";
import SelectInput from "@/components/global/SelectInput.vue";
import SubmitFormButton from "@/components/global/SubmitFormButton.vue";
import axios from "axios";

const router = useRouter();
const route = useRoute();

let name = ref(null);
let type = ref("dev");
let url = ref(null);
let errors = ref([]);

let customer = ref(null);
onMounted(async () => {
    document.getElementsByTagName("input")[0].focus();
    customer.value = (await axios.get("customer/" + route.params.id)).data.customer;
});

const createProject = async () => {
    errors.value = [];
    let data = new FormData();
    data.append("name", name.value || "");
    data.append("type", type.value || "");
    data.append("url", url.value || "");
    data.append("customer_id", customer.value.id || "");

    try {
        const result = await axios.post("project", data);

        if (result.status == 200) {
            flashMessage.show({
                type: "success",
                title: "Speichern erfolgreich",
                text: result.data.message,
                image: "/img/flash-message-icons/success.svg",
            });
            router.push("/project/" + result.data.project_id + "/create-credential");
        }
    } catch (err) {
        errors.value = err.response.data.errors;

        let i = 0;
        Object.values(errors.value).forEach((error) => {
            window.setTimeout(() => {
                flashMessage.show({
                    type: "error",
                    title: "Fehler",
                    text: error,
                    image: "/img/flash-message-icons/error.svg",
                });
            }, i++ * 250);
        });
    }
};
</script>
