<template>
    <div class="mb-4">
        <label for="" class="block uppercase tracking-wide text-xs font-bold mb-2" :class="[labelColor ? 'text-gray-900' : 'text-gray-100']">{{ label }}</label>
        <select
            v-model="inputComputed"
            class="apperance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            :multiple="multiple"
            :disabled="disabled"
        >
            <slot />
        </select>
        <span v-if="error" class="text-red-500">{{ error }}</span>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, toRefs, computed } from "vue";

const emit = defineEmits(["update:input"]);
const props = defineProps({
    label: String,
    labelColor: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    input: [String, Array, Number],
    error: String,
});

const { label, labelColor, input, error } = toRefs(props);

const inputComputed = computed({
    get: () => input.value,
    set: (val) => emit("update:input", val),
});
</script>

<style></style>
