<template>
    <div class="container max-w-4xl mx-auto flex">
        <div class="w-1/3">
            <img class="w-full rounded-lg h-auto shadow-lg" :src="userStore.image" alt="Profile Pic" />
        </div>
        <div class="w-full pl-4">
            <div class="flex">
                <div class="w-1/2">
                    <h1 class="text-2xl md:text4xl text-left text-gray-900">{{ userStore.firstName }} {{ userStore.lastName }}</h1>
                    <span class="text-md text-gray-700">
                        <i><b>Budde Mediendesign</b></i>
                    </span>
                </div>
                <div class="w-1/2 mt-2">
                    <router-link-button :to="'/user/' + userStore.id + '/edit'" color="green">Edit Profile</router-link-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import { useUserStore } from "@/store/user-store";

const userStore = useUserStore();
</script>
