<template>
    <div class="flex justify-between">
        <h2 class="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">Kunden</h2>
        <router-link to="customer/create" class="mb-2 mr-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white cursor-pointer">+</router-link>
    </div>
    <div class="mt-6 flex justify-between">
        <label for="customer-table-search" class="sr-only">Suche</label>
        <div class="relative mt-1">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg class="w-5 h-5 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                    ></path>
                </svg>
            </div>
            <input
                type="text"
                id="customer-table-search"
                class="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50"
                placeholder="Suche"
                v-model="searchString"
                @keyup="search"
            />
        </div>

        <nav class="mt-1" v-if="customer_pagination.last_page > 1">
            <ul class="inline-flex items-center -space-x-px">
                <li>
                    <div
                        @click="prevPage"
                        class="block px-3 py-2 ml-0 leading-tight border border-gray-300 rounded-l-lg"
                        :disabled="customer_pagination.prev_page_url === null"
                        :class="
                            customer_pagination.prev_page_url === null
                                ? 'text-gray-300 bg-gray-100 cursor-default disabled'
                                : 'text-gray-500 bg-white hover:text-gray-700 hover:bg-gray-100 cursor-pointer'
                        "
                    >
                        <span class="sr-only">Previous</span>
                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </div>
                </li>
                <li>
                    <div class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 cursor-default">
                        Seite {{ customer_pagination.current_page }} / {{ customer_pagination.last_page }}
                    </div>
                </li>
                <li>
                    <div
                        @click="nextPage"
                        class="block px-3 py-2 leading-tight border border-gray-300 rounded-r-lg"
                        :disabled="customer_pagination.next_page_url === null"
                        :class="
                            customer_pagination.next_page_url === null
                                ? 'text-gray-300 bg-gray-100 cursor-default disabled'
                                : 'text-gray-500 bg-white hover:text-gray-700 hover:bg-gray-100 cursor-pointer'
                        "
                    >
                        <span class="sr-only">Next</span>
                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </div>
                </li>
            </ul>
        </nav>
    </div>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-4 mx-auto">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs bg-slate-700 text-white">
                <tr>
                    <th scope="col" class="px-6 py-3">Kunde</th>
                    <th v-if="user.agencies.length > 1" scope="col" class="px-6 py-3">Agentur</th>
                    <th scope="col" class="px-6 py-3">Projekte</th>
                    <th scope="col" class="px-6 py-3">Aktionen</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="customer in customer_pagination.data"
                    :key="customer.id"
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                    <td class="px-6 py-4">{{ customer.name }}</td>
                    <td v-if="user.agencies.length > 1" class="px-6 py-4 text-xs">
                        <span
                            class="text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full"
                            :class="
                                'bg-' +
                                customer.agency.name.toLowerCase().replace('ö', 'oe') +
                                ' text-' +
                                customer.agency.name.toLowerCase().replace('ö', 'oe') +
                                ' border-2 border-' +
                                customer.agency.name.toLowerCase().replace('ö', 'oe')
                            "
                        >
                            {{ customer.agency.name }}
                        </span>
                    </td>
                    <td class="px-6 py-4 text-xs">
                        <div v-for="project in customer.projects" :key="project.id">
                            <div class="font-bold">
                                {{ project.name }}
                                <i v-if="project.type == 'live'" class="fa fa-circle text-xs text-red-600 animate-pulse" :title="project.type"></i>
                                <i v-else-if="project.type == 'dev'" class="far fa-circle text-xs text-red-600" :title="project.type"></i>
                            </div>
                            <div>
                                {{ project.credentials.map((credential) => credential.name).join(", ") }}
                            </div>
                        </div>
                    </td>
                    <td class="px-6 py-4">
                        <div class="flex space-x-4">
                            <!-- Modal toggle -->
                            <a
                                href="#"
                                type="button"
                                :data-modal-target="'customer-' + customer.id + '-credentials-box'"
                                :data-modal-show="'customer-' + customer.id + '-credentials-box'"
                            >
                                <i class="fa-regular fa-eye"></i>
                            </a>
                            <router-link :to="'customer/' + customer.id + '/edit'" type="button" v-if="!false">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </router-link>

                            <router-link :to="'customer/' + customer.id + '/create-project'" type="button" v-if="!false">
                                <i class="fa-solid fa-plus text-green-500"></i>
                            </router-link>

                            <a href="#" type="button" v-if="!false">
                                <i class="fa-solid fa-trash text-red-500"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Customer Modal -->
    <div
        v-for="customer in customer_pagination.data"
        :key="customer.id"
        :id="'customer-' + customer.id + '-credentials-box'"
        tabindex="-1"
        aria-hidden="true"
        class="fixed top-0 left-0 right-0 z-50 justify-center hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
    >
        <div class="relative w-full h-full max-w-2xl md:h-auto">
            <!-- Modal content -->
            <form action="#" class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <!-- Modal header -->
                <div class="flex items-start justify-between p-4 border-b rounded-t bg-slate-700 text-white dark:border-gray-600">
                    <h3 class="text-2xl font-semibold">{{ customer.name }}</h3>
                    <button
                        type="button"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        :data-modal-hide="'customer-' + customer.id + '-credentials-box'"
                    >
                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-6 space-y-6">
                    <div v-for="project in customer.projects" :key="project.id">
                        <h4 class="text-xl font-semibold text-gray-900 dark:text-white flex gap-2">
                            {{ project.name }}
                            <i v-if="project.type == 'live'" class="fa fa-circle text-xs text-red-600 animate-pulse" :title="project.type"></i>
                            <i v-else-if="project.type == 'dev'" class="far fa-circle text-xs text-red-600" :title="project.type"></i>
                        </h4>
                        <credentials-box :credentials="project.credentials" :projectId="project.id" :url="project.url" @access-requested="getCustomers" />
                    </div>
                </div>
                <!-- Modal footer -->
            </form>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, nextTick } from "vue";
import { Modal } from "flowbite";
import axios from "axios";
import CredentialsBox from "../components/CredentialsBox.vue";
import { useUserStore } from "@/store/user-store";

const user = useUserStore();
const customer_pagination = ref([]);

let modals = [];
let currentCustomersApiCall = "customers";

onMounted(() => {
    getCustomers();
});

const getCustomers = () => {
    axios.get(currentCustomersApiCall).then((c) => {
        setCustomers(c);
    });
};

const lastSearchString = ref("");
const searchString = ref("");

const search = async () => {
    if (searchString.value !== lastSearchString.value) {
        lastSearchString.value = searchString.value;
        if (searchString.value.length > 0) {
            let converted_search_string = searchString.value.replace(/\s/g, "%");
            currentCustomersApiCall = "customer_search?search=" + converted_search_string;
            getCustomers();
        } else {
            currentCustomersApiCall = "customers";
            getCustomers();
        }
    }
};

const nextPage = async () => {
    currentCustomersApiCall = customer_pagination.value.next_page_url;
    getCustomers();
};

const prevPage = async () => {
    currentCustomersApiCall = customer_pagination.value.prev_page_url;
    getCustomers();
};

const setCustomers = (c) => {
    customer_pagination.value = c.data.customer_pagination;
    modals.length = 0;

    nextTick(() => {
        c.data.customer_pagination.data.forEach((customer) => {
            const $buttonElement = document.querySelector('[data-modal-show="customer-' + customer.id + '-credentials-box"]');
            const $modalElement = document.querySelector("#customer-" + customer.id + "-credentials-box");
            const $closeButton = document.querySelector('[data-modal-hide="customer-' + customer.id + '-credentials-box"]');

            const modalOptions = {
                backdropClasses: "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40",
                placement: "top-center",
            };

            if ($modalElement) {
                const modal = new Modal($modalElement, modalOptions);
                $buttonElement.addEventListener("click", () => modal.show());
                $closeButton.addEventListener("click", () => modal.hide());

                modals.push(modal);
            }
        });
    });
};
</script>
