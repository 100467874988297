<template>
    <div id="edit-profile" class="container max-w-4xl mx-auto py-20 px-6">
        <div class="text-gray-900 text-xl">
            <template v-if="project">{{ project.customer.name }} - {{ project.name }} ({{ project.type }}):</template>
            Zugangsdaten anlegen
        </div>
        <div class="bg-slate-700 w-full h-1"></div>

        <div class="flex flex-wrap mt-4 mb-6">
            <div class="sm:w-1/2 w-full px-3">
                <select-input label="Typ" v-model:input="type" :error="errors.type ? errors.type[0] : ''" @change="updateShownFields">
                    <option value="webhost">Webhost</option>
                    <option value="login">Login</option>
                    <option value="ftp">(S)FTP</option>
                    <option value="ssh">SSH</option>
                    <option value="db">Datenbank</option>
                    <option value="pop">E-Mail (POP)</option>
                    <option value="imap">E-Mail (IMAP)</option>
                    <option value="smtp">E-Mail (SMTP)</option>
                    <option value="file">Datei</option>
                    <option value="other">Andere</option>
                </select-input>
            </div>
            <div class="sm:w-1/2 w-full px-3">
                <text-input label="Name" input-type="text" v-model:input="name" :error="errors.name ? errors.name[0] : ''"></text-input>
                <div v-if="type === 'other' || shownFields.indexOf('url') !== -1" class="w-full">
                    <text-input label="URL" input-type="text" v-model:input="url" :error="errors.url ? errors.url[0] : ''"></text-input>
                </div>
                <div v-if="type === 'other' || shownFields.indexOf('host') !== -1" class="w-full">
                    <text-input label="Host" input-type="text" v-model:input="host" :error="errors.host ? errors.host[0] : ''"></text-input>
                </div>
                <div v-if="type === 'other' || shownFields.indexOf('username') !== -1" class="w-full">
                    <text-input label="Benutzername" input-type="text" v-model:input="username" :error="errors.username ? errors.username[0] : ''"></text-input>
                </div>
                <div v-if="type === 'other' || shownFields.indexOf('password') !== -1" class="w-full">
                    <text-input label="Passwort" input-type="text" v-model:input="password" :error="errors.password ? errors.password[0] : ''"></text-input>
                </div>
                <div v-if="type === 'other' || shownFields.indexOf('projectNo') !== -1" class="w-full">
                    <text-input
                        label="Projekt-Nummer"
                        input-type="text"
                        v-model:input="projectNo"
                        :error="errors.projectNo ? errors.projectNo[0] : ''"
                    ></text-input>
                </div>
                <div v-if="type === 'other' || shownFields.indexOf('phpVersion') !== -1" class="w-full">
                    <text-input
                        label="PHP-Version"
                        input-type="text"
                        v-model:input="phpVersion"
                        :error="errors.phpVersion ? errors.phpVersion[0] : ''"
                    ></text-input>
                </div>
            </div>
            <div class="w-full px-3">
                <textarea-input label="Notizen" input-type="text" v-model:input="notes" :error="errors.notes ? errors.notes[0] : ''"></textarea-input>
            </div>

            <div class="w-1/12 px-3">
                <label for="private_check" class="block uppercase tracking-wide text-xs font-bold mb-2 text-gray-900">Privat</label>
                <input
                    id="private_check"
                    type="checkbox"
                    v-model="isPrivate" @change="changePrivate"
                    class="apperance-none block text-gray-700 border border-gray-400 rounded p-3 leading-tight focus:outline-none focus:ring-0 focus:ring-offset-0"
                    value="true"
                />
                <span v-if="errors.private" class="text-red-500">{{ errors.private[0] }}</span>
            </div>
            <div class="w-11/12 px-3" v-if="isPrivate && userStore.roles && userStore.roles.find((role) => role.name === 'admin')">
                <select-input label="Benutzer" @change="changeUser" v-model:input="user_id" :error="errors.user_id ? errors.user_id[0] : ''">
                    <option v-for="user in users" :key="user.id" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
                </select-input>
            </div>
            <div class="w-full px-3">
                <submit-form-button @click="createCredential">Zugangsdaten anlegen</submit-form-button>
            </div>
        </div>
        <FlashMessage group="privateCredentialsUserAssign" strategy="single" :position="'bottom left'" />
    </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useUserStore } from "@/store/user-store";
import { flashMessage } from "@smartweb/vue-flash-message";
import TextInput from "@/components/global/TextInput.vue";
import TextareaInput from "@/components/global/TextareaInput.vue";
import SelectInput from "@/components/global/SelectInput.vue";
import SubmitFormButton from "@/components/global/SubmitFormButton.vue";
import PrivateCredentialsUserAssign from "@/components/partials/PrivateCredentialsUserAssign.vue";
import axios from "axios";

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();

let name = ref(null);
let type = ref(null);
let url = ref(null);
let host = ref(null);
let username = ref(null);
let password = ref(null);
let projectNo = ref(null);
let phpVersion = ref(null);
let notes = ref(null);
let isPrivate = ref(false);
let user_id = ref(userStore.id);
let errors = ref([]);

const users = ref({});

let project = ref(null);
onMounted(async () => {
    project.value = (await axios.get("project/" + route.params.id)).data.project;
    users.value = (await axios.get("users/all-of-agency/" + project.value.customer.agency.id)).data.users;
});

let shownFields = ref([]);
const updateShownFields = () => {
    switch (type.value) {
        case "webhost":
            shownFields.value = ["url", "username", "password", "projectNo", "phpVersion"];
            break;

        case "login":
            shownFields.value = ["url", "username", "password"];
            break;

        case "ftp":
        case "ssh":
        case "pop":
        case "imap":
        case "smtp":
            shownFields.value = ["host", "username", "password"];
            break;

        case "db":
            shownFields.value = ["url", "host", "username", "password"];
            break;

        case "file":
            shownFields.value = ["password"];
            break;

        default:
            shownFields.value = [];
            break;
    }

    name.value = type.value.toUpperCase();
};

const changePrivate = () => {
    if (!isPrivate.value) {
        flashMessage.removeAll("privateCredentialsUserAssign");
    } else {
        changeUser();
    }
};
const changeUser = () => {
    if (user_id.value !== userStore.id) {
        flashMessage.show({
            group: "privateCredentialsUserAssign",
            component: PrivateCredentialsUserAssign,
            time: 0,
            clickable: false,
        });
    } else {
        flashMessage.removeAll("privateCredentialsUserAssign");
    }
};

const createCredential = async () => {
    errors.value = [];
    let data = new FormData();
    data.append("name", name.value || "");
    data.append("type", type.value || "");
    data.append("url", url.value || "");
    data.append("host", host.value || "");
    data.append("username", username.value || "");
    data.append("password", password.value || "");
    data.append("projectNo", projectNo.value || "");
    data.append("phpVersion", phpVersion.value || "");
    data.append("notes", notes.value || "");
    data.append("private", isPrivate.value || false);
    data.append("user_id", user_id.value || "");
    data.append("project_id", project.value.id || "");

    try {
        const result = await axios.post("credential", data);

        if (result.status == 200) {
            flashMessage.show({
                type: "success",
                title: "Speichern erfolgreich",
                text: result.data.message,
                image: "/img/flash-message-icons/success.svg",
            });
            router.push("/customers");
        }
    } catch (err) {
        errors.value = err.response.data.errors;

        let i = 0;
        Object.values(errors.value).forEach((error) => {
            window.setTimeout(() => {
                flashMessage.show({
                    type: "error",
                    title: "Fehler",
                    text: error,
                    image: "/img/flash-message-icons/error.svg",
                });
            }, i++ * 250);
        });
    }
};
</script>
