<template>
    <div id="edit-profile" class="container max-w-4xl mx-auto py-20 px-6">
        <div class="text-gray-900 text-xl">Edit Profile</div>
        <div class="bg-green-500 w-full h-1"></div>

        <cropper-modal
            v-if="showModal"
            :min-aspect-ratio-prop="{ width: 8, height: 8 }"
            :max-aspect-ratio-prop="{ width: 8, height: 8 }"
            @croppedImageData="setCroppedImageData"
            @showModal="showModal = false"
        />

        <div class="flex flex-wrap mt-4 mb-6">
            <div class="w-full md:w-1/2 px-3">
                <text-input label="First Name" input-type="text" v-model:input="first_name" :error="errors.first_name ? errors.first_name[0] : ''"></text-input>
            </div>
            <div class="w-full md:w-1/2 px-3">
                <text-input label="Last Name" input-type="text" v-model:input="last_name" :error="errors.last_name ? errors.last_name[0] : ''"></text-input>
            </div>
            <div class="w-full md:w-1/2 px-3">
                <text-input label="New Password" input-type="password" v-model:input="password" :error="errors.password ? errors.password[0] : ''"></text-input>
            </div>
            <div class="w-full md:w-1/2 px-3">
                <text-input label="Confirm new Password" input-type="password" v-model:input="confirmPassword" />
            </div>
            <div class="w-full md:w-1/2 px-3">
                <display-cropper-button label="Profile Image" @showModal="showModal = true">Update Profile Image</display-cropper-button>
            </div>
            <div class="w-full md:w-1/2 px-3">
                <cropped-image label="Cropped Image" :image="image" />
            </div>
            <div class="w-full px-3">
                <submit-form-button @click="updateUser">Update Profile</submit-form-button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useUserStore } from "@/store/user-store";
import { useRouter } from "vue-router";
import TextInput from "@/components/global/TextInput.vue";
import CropperModal from "@/components/global/CropperModal.vue";
import DisplayCropperButton from "@/components/global/DisplayCropperButton.vue";
import CroppedImage from "@/components/global/CroppedImage.vue";
import SubmitFormButton from "@/components/global/SubmitFormButton.vue";
import axios from "axios";

const router = useRouter();
const userStore = useUserStore();

let showModal = ref(false);
let first_name = ref(null);
let last_name = ref(null);
let password = ref(null);
let confirmPassword = ref(null);
let imageData = null;
let image = ref(null);
let errors = ref([]);

onMounted(() => {
    first_name.value = userStore.firstName || null;
    last_name.value = userStore.lastName || null;
    image.value = userStore.image || null;
});

const setCroppedImageData = (data) => {
    imageData = data;
    image.value = data.imageUrl;
};

const updateUser = async () => {
    errors.value = [];
    let data = new FormData();
    data.append("first_name", first_name.value || "");
    data.append("last_name", last_name.value || "");
    if (imageData) {
        data.append("image", imageData.file || "");
        data.append("height", imageData.height || "");
        data.append("width", imageData.width || "");
        data.append("left", imageData.left || "");
        data.append("top", imageData.top || "");
    }

    try {
        await axios.post("users/" + userStore.id + "?_method=PUT", data);
        await userStore.fetchUser();
        router.push("/user/" + userStore.id);
    } catch (err) {
        errors.value = err.response.data.errors;
    }
};
</script>
