<template>
    <div class="w-full bg-slate-800 relative z-20 text-white">
        <div class="w-full max-w-3xl flex justify-between items-center mx-auto px-8">
            <div class="py-4 text-3xl font-bold">PWDB</div>
            <nav class="flex gap-4" v-if="userStore.token">
                <a href="/customers">Kunden</a>
                <a v-if="userStore.roles && userStore.roles.find((role) => role.name === 'admin')" href="/users">Benutzer</a>
                <a href="/logout">Abmelden</a>
            </nav>
        </div>
    </div>
</template>
<script setup>
import { useUserStore } from "@/store/user-store";

const userStore = useUserStore();
</script>
