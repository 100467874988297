<template>
    <label for="" class="block uppercase tracking-wide text-gray-900 text-xs font-bold">{{ label }}</label>
    <button class="mt-2 inline-block bg-green-500 hover:bg-green-700 font-bold text-white no-underline px-4 py-3" @click="$emit('showModal', true)">
        <slot />
    </button>
</template>

<script setup>
import { defineProps, toRefs } from "vue";

const props = defineProps({
    label: { type: String, default: "" },
});

const { label } = toRefs(props);
</script>

<style></style>
