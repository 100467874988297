<template>
    <div id="edit-profile" class="container max-w-4xl mx-auto py-20 px-6">
        <div class="text-gray-900 text-xl">Neuen Kunden anlegen</div>
        <div class="bg-slate-700 w-full h-1"></div>

        <div class="flex flex-wrap mt-4 mb-6">
            <div class="w-full sm:w-1/2 px-3">
                <text-input
                    id="focus"
                    label="Name"
                    input-type="text"
                    v-model:input="name"
                    :error="errors.name ? errors.name[0] : ''"
                    @keyup.enter="createCustomer"
                ></text-input>
            </div>
            <div class="w-full sm:w-1/2 px-3">
                <select-input
                    label="Agentur"
                    v-model:input="agency"
                    :error="errors.agency_id ? errors.agency_id[0] : ''"
                    @keyup.enter="createCustomer"
                    :disabled="agencies.length === 1"
                >
                    <option v-for="agency in agencies" :value="agency.id.toString()" :key="agency.id">{{ agency.name }}</option>
                </select-input>
            </div>
            <div class="w-full px-3">
                <submit-form-button @click="createCustomer">Kunde anlegen</submit-form-button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { flashMessage } from "@smartweb/vue-flash-message";
import TextInput from "@/components/global/TextInput.vue";
import SelectInput from "@/components/global/SelectInput.vue";
import SubmitFormButton from "@/components/global/SubmitFormButton.vue";
import axios from "axios";

const router = useRouter();

let name = ref(null);
let agency = ref(null);
let errors = ref([]);

let agencies = ref([]);

onMounted(async () => {
    agencies.value = (await axios.get("agencies")).data;
    if(agencies.value.length === 1) {
        agency.value = agencies.value[0].id.toString();
    }
});

onMounted(() => {
    document.getElementsByTagName("input")[0].focus();
});

const createCustomer = async () => {
    errors.value = [];
    let data = new FormData();
    data.append("name", name.value || "");
    data.append("agency_id", agency.value || "");

    try {
        const result = await axios.post("customer", data);

        if (result.status == 200) {
            flashMessage.show({
                type: "success",
                title: "Speichern erfolgreich",
                text: result.data.message,
                image: "/img/flash-message-icons/success.svg",
            });
            router.push("/customer/" + result.data.customer_id + "/create-project");
        }
    } catch (err) {
        errors.value = err.response.data.errors;

        let i = 0;
        Object.values(errors.value).forEach((error) => {
            window.setTimeout(() => {
                flashMessage.show({
                    type: "error",
                    title: "Fehler",
                    text: error,
                    image: "/img/flash-message-icons/error.svg",
                });
            }, i++ * 250);
        });
    }
};
</script>
