<template>
    <div id="edit-profile" class="container max-w-4xl mx-auto py-20 px-6">
        <div class="text-gray-900 text-xl">Kunde bearbeiten</div>
        <div class="bg-slate-700 w-full h-1"></div>

        <div class="flex flex-wrap mt-4 mb-6">
            <div class="w-full sm:w-1/2 px-3">
                <text-input
                    id="focus"
                    label="Name"
                    input-type="text"
                    v-model:input="customer.name"
                    :error="errors.name ? errors.name[0] : ''"
                    @keyup.enter="updateCustomer"
                ></text-input>
            </div>
            <div class="w-full sm:w-1/2 px-3">
                <select-input
                    label="Agentur"
                    v-model:input="customer.agency.id"
                    :error="errors.agency_id ? errors.agency_id[0] : ''"
                    @keyup.enter="updateCustomer"
                    :disabled="agencies.length === 1"
                >
                    <option v-for="agency in agencies" :value="agency.id.toString()" :key="agency.id">{{ agency.name }}</option>
                </select-input>
            </div>
            <div class="w-full px-3">
                <submit-form-button @click="updateCustomer">Änderungen speichern</submit-form-button>
            </div>
        </div>

        <div class="text-gray-900 text-xl">Projekte</div>
        <div class="bg-slate-700 w-full h-1"></div>

        <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-4 mx-auto">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs bg-slate-700 text-white">
                    <tr>
                        <th scope="col" class="px-6 py-3">Name</th>
                        <th scope="col" class="px-6 py-3">URL</th>
                        <th scope="col" class="px-6 py-3">Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="project in customer.projects"
                        :key="project.id"
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                        <td class="px-6 py-4">
                            {{ project.name }}
                            <i v-if="project.type == 'live'" class="fa fa-circle text-xs text-red-600 animate-pulse" :title="project.type"></i>
                            <i v-else-if="project.type == 'dev'" class="far fa-circle text-xs text-red-600" :title="project.type"></i>
                        </td>
                        <td class="px-6 py-4">{{ project.url }}</td>
                        <td class="px-6 py-4">
                            <div class="flex space-x-4">
                                <router-link :to="'/project/' + project.id + '/edit'" type="button" v-if="!false">
                                    <i class="fa-solid fa-pen-to-square"></i>
                                </router-link>

                                <a href="#" type="button" v-if="!false">
                                    <i class="fa-solid fa-trash text-red-500"></i>
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { flashMessage } from "@smartweb/vue-flash-message";
import TextInput from "@/components/global/TextInput.vue";
import SelectInput from "@/components/global/SelectInput.vue";
import SubmitFormButton from "@/components/global/SubmitFormButton.vue";
import axios from "axios";

const route = useRoute();

const customer = ref({ name: "", agency: { id: "" } });
const agencies = ref([]);
const errors = ref([]);

onMounted(async () => {
    customer.value = (await axios.get("customer/" + route.params.id)).data.customer;
    agencies.value = (await axios.get("agencies")).data;
    if (agencies.value.length === 1) {
        customer.value.agency.id = agencies.value[0].id.toString();
    }
});

onMounted(() => {
    document.getElementsByTagName("input")[0].focus();
});

const updateCustomer = async () => {
    errors.value = [];
    let data = new FormData();
    data.append("name", customer.value.name || "");
    data.append("agency_id", customer.value.agency.id || "");
    try {
        const result = await axios.post("customer/" + route.params.id + "?_method=PUT", data);

        if (result.status == 200) {
            flashMessage.show({
                type: "success",
                title: "Speichern erfolgreich",
                text: result.data.message,
                image: "/img/flash-message-icons/success.svg",
            });
        }
    } catch (err) {
        errors.value = err.response.data.errors;

        let i = 0;
        Object.values(errors.value).forEach((error) => {
            window.setTimeout(() => {
                flashMessage.show({
                    type: "error",
                    title: "Fehler",
                    text: error,
                    image: "/img/flash-message-icons/error.svg",
                });
            }, i++ * 250);
        });
    }
};
</script>
